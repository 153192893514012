import { useCallback, useEffect, useState } from "react";
import { useEvent } from "react-use";
import { useScript } from "usehooks-ts";

import { useAppDispatch } from "./redux";
import { useThemeUtils } from "./theme";
import { useHostedInterface } from "./useHostedInterface";

import { scriptSrc, uneeqConfig } from "../common/uneeq";
import {
  setHostedAvatarSpeaking,
  setHostedAvatarThinking,
  setHostedCustomData,
  setSessionState,
} from "../store/uneeq/actions";
import { HostedUneeqMessage, PromptResult, UneeqEvent, UneeqSessionMessage, UneeqSessionState } from "../types/uneeq";

export const useHostedDigitalHuman = () => {
  const { isMobile } = useThemeUtils();

  const dispatch = useAppDispatch();
  const { hostedSessionState, layoutMode, showUserInput } = useHostedInterface();
  const [uneeq, setUneeq] = useState<Uneeq | null>(null);

  const uneeqFrame = document.getElementById("uneeqFrame");

  const status = useScript(scriptSrc);

  useEffect(() => {
    if (uneeqFrame || status !== "ready") {
      return;
    }

    const uneeq = new Uneeq(uneeqConfig(isMobile));
    uneeq.init();
    setUneeq(uneeq);
    window.uneeq = uneeq;
  }, [uneeqFrame, status, isMobile]);

  const onUneeqMessage = useCallback(
    (event: UneeqEvent) => {
      const message = event.detail as HostedUneeqMessage;

      if (message.uneeqMessageType === "ReadyToStart") {
        dispatch(setSessionState(UneeqSessionState.Initial));
      }

      if (message.uneeqMessageType === "SessionStateUpdate") {
        const { state } = message as UneeqSessionMessage;
        dispatch(setSessionState(state));
      }

      if (message.uneeqMessageType === "AvatarStartedSpeaking") {
        dispatch(setHostedAvatarSpeaking(true));
        dispatch(setHostedAvatarThinking(false));
      }

      if (message.uneeqMessageType === "AvatarStoppedSpeaking") {
        dispatch(setHostedAvatarSpeaking(false));
      }

      if (message.uneeqMessageType === "PromptResult") {
        const { promptResult } = message as PromptResult;

        console.log(promptResult);

        try {
          const { customData: hostedCustomData } = promptResult.response.metadata;

          if (!hostedCustomData) {
            return;
          }

          dispatch(setHostedCustomData(hostedCustomData));
        } catch (e) {
          console.warn("Could not parse answerAvatar payload");
          console.warn(e);
        }
      }
    },
    [dispatch]
  );

  useEvent("UneeqMessage", onUneeqMessage);

  // Layout Mode Control
  useEffect(() => {
    if (hostedSessionState === undefined) {
      return;
    }

    uneeq?.setLayoutMode(layoutMode);
  }, [layoutMode, hostedSessionState, uneeq]);

  // Input Control
  useEffect(() => {
    uneeq?.setShowUserInputInterface(showUserInput);
  }, [showUserInput, uneeq]);
};
