import "./i18n";
import "./index.css";

import { Experimental_CssVarsProvider as CssVarsProvider, useColorScheme } from "@mui/material/styles";
import { useEffect } from "react";

import { Avatar } from "./components/CallToAction";
import { useAppSelector } from "./hooks/redux";
import { useHostedDigitalHuman } from "./hooks/useHostedDigitalHuman";
import { useHostedInterface } from "./hooks/useHostedInterface";
import { InteractionPage } from "./pages/InteractionPage";
import { selectSessionState } from "./store/uneeq";
import { theme } from "./theme";
import { hideElements, showElements } from "./utils/page";

export const App = () => {
  useHostedDigitalHuman();
  const { hostedLive } = useHostedInterface();
  const isLive = useAppSelector(selectSessionState);

  useEffect(() => {
    if (isLive === "Live") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return hideElements();
    }

    showElements();
    document.getElementById("uneeqFrame")?.style?.setProperty("position", "fixed"); // Hosted doesn't reset otherwise
  }, [isLive]);

  const DefaultMode = () => {
    const { setMode } = useColorScheme();
    useEffect(() => {
      setMode("dark");
    }, [setMode]);
    return <></>;
  };

  return (
    <CssVarsProvider theme={theme()}>
      <DefaultMode />
      <InteractionPage hidden={!hostedLive} hostedLive={hostedLive} />
      <Avatar />
    </CssVarsProvider>
  );
};
