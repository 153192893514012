import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import { BouncingDots } from "./Loaders/BouncingDots";

import avatarImage from "../assets/img/avatar.png";
import { uneeqConfig } from "../common/uneeq";
import { useHostedInterface } from "../hooks/useHostedInterface";

export const Avatar = () => {
  const [showCTAMessage, setShowCTAMessage] = useState(true);
  const { launchHostedAvatar, hostedLive, hostedReady, hostedLoading } = useHostedInterface();

  const showCTA = !hostedLive && hostedReady;
  const showSpeechBubble = showCTAMessage || hostedLoading;
  const showSpeechBubbleButton = !hostedLoading;
  // @ts-ignore
  const defaultCallToActionText = uneeqConfig(false)?.languageStrings?.default?.callToActionText;

  return (
    <AnimatePresence>
      {showCTA && (
        <Stack
          direction="row"
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          sx={{
            position: "fixed",
            right: 8,
            bottom: 0,
            alignItems: "center",
            pl: 1,
          }}
        >
          <AnimatePresence>
            {showSpeechBubble && (
              <Stack
                component={motion.div}
                direction="row"
                gap={1}
                sx={{ alignItems: "center" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {showSpeechBubbleButton && (
                  <IconButton
                    onClick={() => setShowCTAMessage(false)}
                    sx={{
                      backgroundColor: "#4B1E64",
                      "&:hover": {
                        backgroundColor: "#943bc4",
                      },
                      height: 32,
                      width: 32,
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
                <Box
                  onClick={() => launchHostedAvatar()}
                  sx={{
                    color: "white",
                    borderRadius: "6px",
                    backgroundColor: "#855BA9",
                    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                    py: 1,
                    px: 2,
                    mr: -2,
                    cursor: "pointer",
                  }}
                >
                  {hostedLoading ? (
                    <BouncingDots />
                  ) : (
                    <Typography fontWeight="bold" fontSize={[14, 16, 18]}>
                      {defaultCallToActionText}
                    </Typography>
                  )}
                </Box>
              </Stack>
            )}
          </AnimatePresence>
          <Box
            sx={{
              minHeight: [160, 180, 200],
              minWidth: [160, 180, 200],
              backgroundImage: `url(${avatarImage})`,
              backgroundSize: "cover",
              cursor: "pointer",
            }}
            onClick={() => launchHostedAvatar()}
          />
        </Stack>
      )}
    </AnimatePresence>
  );
};
