import { ArrowForward } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { loadDefaultJapaneseParser } from "budoux";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

import { PromptButton } from "./Buttons/PromptButton";
import { CustomCarousel } from "./CustomCarousel";
import { TextInput } from "./TextInput";

import { useThemeUtils } from "../hooks/theme";
import { OnScreenContent } from "../types/uneeq";
import "./SlideContent.css";

const VideoSlide = ({
  url,
  isSelected,
}: {
  url: string;
  isSelected?: boolean;
}) => {
  const playerRef: MutableRefObject<any> = useRef();

  useEffect(() => {
    playerRef.current.seekTo(0); // reset video to start
  }, [isSelected]);

  return (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      ref={playerRef}
      playing={isSelected}
      controls={true}
    />
  );
};

export interface CustomData {
  onScreenContent?: OnScreenContent;
}

export const SlideContent = ({ onScreenContent }: CustomData) => {
  const {
    title,
    step,
    body,
    imageUrls = [],
    buttons,
    videoUrl,
    textInput,
  } = onScreenContent || {};
  const theme = useTheme();
  const { isMobile } = useThemeUtils();
  const { t } = useTranslation();
  const parser = loadDefaultJapaneseParser();

  const renderStep = (visible: boolean) => (
    <Stack
      direction="row"
      maxWidth="auto"
      minWidth={0}
      {...((!visible || !step) && { visibility: "hidden" })}
      justifyContent="end"
      sx={{ pr: 1, pt: 1.5 }}
    >
      <Typography
        noWrap
        sx={{ fontWeight: "bold", color: theme.vars.palette.text.secondary }}
      >
        {isMobile ? step : t("SlideContent.step", { step })}
      </Typography>
      <ArrowForward sx={{ color: theme.vars.palette.text.secondary }} />
    </Stack>
  );

  const mediaUrls = videoUrl ? imageUrls?.concat([videoUrl]) : imageUrls;

  return (
    <Stack
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "999px",
          backgroundColor: "rgba(0, 0, 0, .2)",
        },
        p: "4px",
      }}
      direction="row"
    >
      {step && renderStep(true)}
      <Stack flexGrow={1} pb={`${theme.drawerOverflowPadding}px`} gap={1}>
        {title && (
          <Typography
            variant="display"
            dangerouslySetInnerHTML={{
              __html: parser.translateHTMLString(title),
            }}
            sx={{
              color: theme.vars.palette.primary.main,
            }}
          ></Typography>
        )}
        <Stack gap={2}>
          <CustomCarousel>
            {mediaUrls?.map((url, index) => {
              const type = url.split(".").pop();
              return type === "mp4" ? (
                <VideoSlide url={url} key={index} />
              ) : (
                <img
                  style={{ maxWidth: "100%" }}
                  key={index}
                  src={url}
                  alt=""
                />
              );
            })}
          </CustomCarousel>
          <Stack gap={2}>
            {body && (
              <div
                className="SlideBody"
                dangerouslySetInnerHTML={{
                  __html: parser.translateHTMLString(body),
                }}
              ></div>
            )}
            {textInput && <TextInput textInput={textInput} />}
            <Stack
              gap={2}
              paddingTop={!textInput && !body ? "16px" : undefined}
            >
              {buttons?.map(({ label, uneeqAsk }, index) => (
                <PromptButton key={index} utterance={uneeqAsk}>
                  {label}
                </PromptButton>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {step && renderStep(false)}
    </Stack>
  );
};
