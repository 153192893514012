import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "./redux";

import {
  selectHostedAvatarSpeaking,
  selectHostedAvatarThinking,
  selectHostedCustomData,
  selectHostedVisible,
  selectLayoutMode,
  selectSessionState,
  selectShowUserInputHosted,
} from "../store/uneeq";
import { setHostedAvatarThinking, setHostedVisible } from "../store/uneeq/actions";
import { UneeqSessionState } from "../types/uneeq";

export const useHostedInterface = () => {
  const { uneeq } = window;
  const dispatch = useAppDispatch();
  const hostedSessionState = useAppSelector(selectSessionState);
  const hostedAvatarSpeaking = useAppSelector(selectHostedAvatarSpeaking);
  const hostedAvatarThinking = useAppSelector(selectHostedAvatarThinking);
  const hostedVisible = useAppSelector(selectHostedVisible);
  const layoutMode = useAppSelector(selectLayoutMode);
  const showUserInput = useAppSelector(selectShowUserInputHosted);
  const hostedCustomData = useAppSelector(selectHostedCustomData);

  // Statuses
  const hostedInitialised = hostedSessionState === UneeqSessionState.Initial;
  const hostedLoading = hostedSessionState === UneeqSessionState.WaitingToStart;
  const hostedLive = hostedSessionState === UneeqSessionState.Live;
  const hostedReady = hostedSessionState !== undefined;
  const showHosted = hostedLive && hostedVisible;

  const launchHostedAvatar = useCallback(() => {
    dispatch(setHostedVisible(true));
    dispatch(setHostedAvatarThinking(true));
    uneeq.startSession?.();
  }, [dispatch, uneeq]);

  const hideHostedAvatar = useCallback(() => {
    dispatch(setHostedVisible(false));
  }, [dispatch]);

  const uneeqAsk = useCallback(
    (question: string) => {
      uneeq?.chatPrompt(question);
    },
    [uneeq]
  );

  const uneeqEndSession = useCallback(() => {
    uneeq?.endSession();
  }, [uneeq]);

  return {
    hostedAvatarSpeaking,
    hostedAvatarThinking,
    hostedCustomData,
    showHosted,
    hostedLoading,
    hostedLive,
    layoutMode,
    showUserInput,
    hostedSessionState,
    hostedInitialised,
    hostedReady,
    launchHostedAvatar,
    hideHostedAvatar,
    uneeqAsk,
    uneeqEndSession,
  };
};
