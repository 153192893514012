/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    uneeq: Uneeq;
  }

  interface UneeqOptions {
    // Required properties
    personaId: string;
    connectionUrl: string;

    // Layout and display options
    layoutMode?: "fullScreen" | "overlay" | "contained";
    displayCallToAction?: boolean;
    renderContent?: boolean;
    customStyles?: string;
    containedAutoLayout?: boolean;
    mobileViewWidthBreakpoint?: number;

    // Initialization and behavior
    initLoadHandler?: boolean;
    autoStart?: boolean;
    welcomePrompt?: string;

    // Camera and view settings
    cameraAnchorHorizontal?: string;
    cameraAnchorDistance?: string;

    // Audio and interaction settings
    enableMicrophone?: boolean;
    enableVad?: boolean;
    enableInterruptBySpeech?: boolean;
    showUserInputInterface?: boolean;
    showClosedCaptions?: boolean;
    speechRecognitionHintPhrasesBoost?: number;
    speechRecognitionLocales?: string;

    // Customization
    languageStrings?: Record<string, unknown>;

    // Debug and logging
    logLevel?: "debug" | "info" | "warn" | "error";
    verboseLogging?: boolean;

    // Session data (typically set internally)
    token?: string;
    sessionId?: string;
    mapPath?: string;

    // Animation timing
    layoutModeChangeSpeedMs?: number;

    // Other
    customMetadata?: any;
  }

  interface CameraAnchorOptions {
    position: string;
    duration: number;
  }

  interface LayoutModeOptions {
    layoutMode: "fullScreen" | "overlay" | "contained";
  }

  interface ShowOptions {
    show: boolean;
  }

  class Uneeq {
    constructor(options: UneeqOptions);

    private options: UneeqOptions;
    private scrollTicking: boolean;
    private containedLayoutElem: HTMLElement | null;
    private layoutTransitionInProgress: boolean;
    private layoutMode: "fullScreen" | "overlay" | "contained" | null;
    private frameReady: boolean;
    private sessionLive: boolean;
    private uneeqFrame: HTMLIFrameElement;

    private addLoadHandler(): void;
    private validateOptions(): boolean;
    private getHostedExperienceUrl(): string;
    private addFrame(): void;
    private validateLayoutModeChangeSpeedMs(): void;
    private validateLayoutMode(layoutMode: string): boolean;
    private postToFrame(type: string, value: any): void;
    private getContainedLayoutElem(): HTMLElement | null;
    private initScrollHandler(): void;
    private frameFadeOutIn(fadedOutCallback: () => void, layoutMode: string): void;
    private isMobileLayoutModeEnabled(): boolean;
    private handleClientResize(): void;
    private frameReadyHandler(): void;
    private handleTokenRejection(error: { message: string; status?: number }): void;
    private initResizeHandler(): void;
    private isPersonaIdValidGuid(personaId: string): boolean;
    private sendEventMessage(eventDetail: CustomEventInit): void;
    private onMessage(event: MessageEvent): void;
    private handleLogMessages(type: string, data: any[]): void;
    private showSnackbar(message: string, timeout?: number): void;

    // Public methods
    public init(): void;
    public cameraAnchor(position: string, duration: number): void;
    public chatPrompt(prompt: string): void;
    public speak(speech: string): void;
    public enableMicrophone(): void;
    public endSession(): void;
    public pauseSpeechRecognition(): void;
    public resumeSpeechRecognition(): void;
    public setCustomChatMetadata(customMetadata: any): void;
    public setLayoutMode(layoutMode: string): void;
    public stopSpeaking(): void;
    public unmuteDigitalHuman(): void;
    public updateDisplayContent(htmlContent: string): void;
    public setShowClosedCaptions(show: boolean): void;
    public setShowUserInputInterface(show: boolean): void;
    public startSession(): void;
  }
}

export enum UneeqSessionState {
  Initial = "Initial",
  WaitingToStart = "WaitingToStart",
  AvatarUnavailable = "AvatarUnavailable",
  MajorError = "MajorError",
  Live = "Live",
  Paused = "Paused",
  Ended = "Ended",
}

export interface UneeqSessionMessage extends HostedUneeqMessage {
  uneeqMessageType: "SessionStateUpdate";
  state: UneeqSessionState;
  live: boolean;
}

export interface AvatarAnswerMessage extends HostedUneeqMessage {
  uneeqMessageType: "AvatarAnswer";
  answer: string;
  answerAvatar: string;
}

export interface PromptResult extends HostedUneeqMessage {
  uneeqMessageType: "PromptResult";
  promptResult: {
    request: {
      prompt: string;
      metadata: any;
    };
    response: {
      metadata: {
        customData?: CustomData;
      };
    };
  };
}

type UneeqMessageType =
  | "SessionStateUpdate"
  | "AvatarAnswer"
  | "AvatarStartedSpeaking"
  | "AvatarStoppedSpeaking"
  | "ReadyToStart"
  | "PromptResult";

export interface HostedUneeqMessage {
  uneeqMessageType: UneeqMessageType;
}

export interface UneeqEvent extends Event {
  detail: HostedUneeqMessage;
}

export type LayoutMode = "overlay" | "splitScreen" | "fullScreen" | "contained";

export type AvatarInteractionState = "idle" | "listening" | "thinking" | "disabled";

export interface ContentButton {
  label: string;
  uneeqAsk: string;
}

export interface TextInputField {
  placeholderText: string;
  buttonText: string;
}

export interface OnScreenContent {
  title?: string;
  textInput?: TextInputField;
  step?: number;
  totalSteps?: number;
  body?: string;
  imageUrls?: string[];
  videoUrl?: string;
  buttons?: ContentButton[];
}

export interface CustomData {
  onScreenContent?: OnScreenContent;
  conversationEnd?: boolean;
  disclaimer?: boolean;
}
