const locale = "ja-JP";

const personaId = process.env["REACT_APP_UNEEQ_PERSONA_ID"] ?? "";
const connectionUrl = process.env["REACT_APP_UNEEQ_CONNECTION_URL"] ?? "";
export const scriptSrc = process.env["REACT_APP_UNEEQ_SCRIPT_SRC"] ?? "";

export const uneeqConfig = (isMobile = false): UneeqOptions => ({
  connectionUrl,
  personaId,
  displayCallToAction: false,
  layoutMode: "contained",
  showUserInputInterface: true,
  customStyles: ".settings-btn { display: none; }",
  customMetadata: {},
  initLoadHandler: false,
  cameraAnchorHorizontal: isMobile ? "center" : "right",
  cameraAnchorDistance: "loose_close_up",
  welcomePrompt: "Hi",
  speechRecognitionLocales: locale,
  showClosedCaptions: false,
  languageStrings: {
    default: {
      callToActionText: "案内人の篠原が「かけはし」についてより詳しくお話しします",
      textInputPlaceholder: "話すか入力する",
      errorText: "エラーが発生しました。",
      showContentToolTip: "コンテンツを表示",
      sendQuestionButtonToolTip: "メッセージを送る",
      startRecordingButtonToolTip: "録音を開始",
      unmuteMicrophoneToolTip: "マイクをオンにする",
      muteMicrophoneToolTip: "マイクをオフにする",
      microphonePermissionNotAllowedButtonToolTip: "マイクの許可が許可されていません",
      microphoneEnabledHintTitle: "マイクが有効",
      microphoneEnabledHintText: "私に話しかけてください、聞いています。",
      microphoneEnabledHintTitleOverlay: "マイクが有効",
      microphoneNotAllowedMessage:
        "マイクの使用が許可されませんでした。ブラウザの設定でマイクへのアクセスを許可して、もう一度お試しください。",
      recordingTapToStart: "録音するにはタップして、送信するには再度タップしてください",
      recordingSpeakNowPrompt: "今話してください",
      recordingTapStopWhenDone: "終了する時は停止をタップしてください",
      recordingStopButtonToolTip: "録音を停止",
      recordingRequestingMicrophonePrompt: "マイクをリクエスト中",
      settingsExitButton: "終了",
      confirmExitDescription: "本当に終了しますか？",
      confirmExitStayButton: "残る",
      confirmExitEndButton: "終了",
      hideContentButton: "コンテンツを隠す",
      hideDigitalHumanButton: "デジタルヒューマンを隠す",
    },
  },
});

export const welcomeQuery = "Hi";
